import api from '../../../utils/api'

// 授权
export async function add_address(payload) {
  return api.post(`/api/v1/mp/address/add`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}








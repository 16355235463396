<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <van-form @submit="onSubmit">
        <div class="list">
          <van-field
            v-model="recipient"
            name="recipient"
            label="收件人"
            placeholder="请设置收件人"
            :rules="[{ required: true, message: '请填写收件人' }]"
          />
          <van-field
            v-model="phone"
            name="phone"
            label="手机号"
            placeholder="手机号"
            :rules="[{ pattern, message: '请输入正确的手机号' }]"
          />
          <van-field
            v-model="id_card"
            name="id_card"
            label="身份证号"
            placeholder="请设置身份证号"
            :rules="[{ required: true, message: '请填写身份证号' }]"
          />
          <van-field
            v-model="address"
            name="address"
            label="收件地址"
            placeholder="请设置收件地址"
            :rules="[{ required: true, message: '请填写收件地址' }]"
          />
        </div>
        <div class="add_box">
          <van-button
            class="add_btn"
            round
            block
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { add_address } from './service'
export default {
  data() {
    return {
      data: {},
      loading: false,
      id: this.$route.query.id,
      recipient: this.$route.query.recipient,
      phone: parseInt(this.$route.query.phone) || null,
      address: this.$route.query.address,
      id_card: this.$route.query.id_card,
      order_id: this.$route.query.order_id,
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      pattern2: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  destroyed() {},
  methods: {
    onSubmit(values) {
      console.log(values)
      this.add(values)
    },
    async add(params) {
      params.id = this.id
      this.loading = true
      try {
        await add_address(params)
        this.$notify({ type: "success", message: this.id?'修改成功':"新增成功！" })
        setTimeout(()=>{
          this.$router.go(-1)
        }, 1000)
      } finally {
        this.loading = false
      }
    },
  },
};
</script>

<style scoped >
.g_main_content {
  background: #f7f7f7;
  padding-top: 17px;
}

.list {
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

>>>.van-cell {
  padding: 14px 16px;
}

>>>.van-field__label {
  font-weight: 500;
}

.add_box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  padding: 10px 21px;
}

.add_btn {
  height: 40px;
  background: #0088ff;
  border-radius: 9px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
</style>